import React from 'react'
import corporate from '../../../assets/images/landingImages/corporate.png'
import speakerOutline from '../../../assets/images/landingImages/speaker-outline.png'
import speaker from '../../../assets/images/landingImages/speaker.png'
import speakerSmall from '../../../assets/images/landingImages/speaker-small.png'
import gift from '../../../assets/images/landingImages/gift.png'
import LandingLayout from './Layout/LandingLayout'
import '../../../assets/css/landingstyles.css'

const LandingReserveBooth = () => {
    return (

        <LandingLayout>

            <div>
                <div className="reserve-booth section-gap">
                    <div className="container">
                        <div className="box-image">
                            <img src={corporate} alt="Image" />
                        </div>
                        <h1 className="title">Reserve a Booth</h1>
                        <ul className="reserve-list">
                            <li>Choose</li>
                            <li>Rent</li>
                            <li>Enjoy!</li>
                        </ul>
                        <div className="product-wrapper">
                            <div className="product-left">
                                <img src={speakerOutline} />
                            </div>
                            <div className="product-center">
                                <img src={speaker} />
                            </div>
                            <div className="product-right">
                                <img src={speakerSmall} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reserve-bottom section-gap">
                    <div className="container">
                        <div className="bottom-box-wrapper">
                            <div className="reserve-bottom-left">
                                <div className="pharmabooth-coupen">
                                    <h3 className="pharma-title">Package Include </h3>
                                    <ul className="pharma-list">
                                        <li>Booth drop off</li>
                                        <li>Custom Digital Layouts</li>
                                        <li>Animated GIFS, Layouts &amp; Still photos </li>
                                        <li>ALL Photos will be sink via link to the customer </li>
                                        <li>Green Screen</li>
                                        <li>Wireless Printing</li>
                                        <li>Social Sharing</li>
                                        <li>Props</li>
                                        <li>Email and SMS sharing</li>
                                    </ul>
                                    <div className="gift">
                                        <h4><span><img src={gift} alt="Gift Image" /></span> Delivery Fee Included Rent a Booth</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="reserve-bottom-right">
                                <form method="post">
                                    <div className="contact-form">
                                        <div className="form-row">
                                            <input type="text" placeholder="Your Name" className="form-control" />
                                            <input type="email" placeholder="Your Email" className="form-control" />
                                        </div>
                                        <div className="form-row">
                                            <input type="text" placeholder="Your Phone (optional)" className="form-control" />
                                        </div>
                                        <input type="button" className="sbtn" defaultValue="Rent a Booth" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </LandingLayout>
    )
}

export default LandingReserveBooth
