import React from 'react'
import SEO from '../components/seo'
import LandingReserveBooth from '../Views/Home/LandigPage/LandingReserveBooth'

const LoginPage = () => {	
	
	return (
		<>
			<SEO title="reserve booth" />
				<LandingReserveBooth />
		</>
	)
}

export default LoginPage
